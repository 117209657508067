/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Paper, TableHead, TextField, Grid, Button } from '@mui/material';
import { addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../.././../firebase/config.js';
import { useEffect } from 'react';
import { createCreditsTableRowData } from '../../StationList/utils/constants.js';
import VacationWithAirFareTable from './VacationWithAirFareTable';
import SpecialtyVacationTable from './SpecialtyVacationTable';
import { useSnackbar } from 'notistack';
import useAuthContext from '../../../hooks/useAuthContext.js';

const vacationObjectKeys = [
	'5-Day Carnival Cruise from Miami, FL',
	'5-Day Carnival Cruise from New Orleans, LA',
	'7-Day Carnival Alaskan Cruise from Seattle, WA',
	'Atlanta, GA',
	'Biloxi, MS',
	'Breckenridge, CO',
	'Chicago, IL',
	'Dallas, TX',
	'Daytona Beach, FL',
	'Gatlinburg, TN',
	'Jackson Hole, WY',
	'Key West, FL',
	'Las Vegas, NV',
	'Los Angeles, CA',
	'Memphis, TN',
	'Miami Beach, FL',
	'Myrtle Beach, SC',
	'Nashville, TN',
	'New Orleans, LA',
	'New York, NY',
	'Orlando Attractions, FL',
	'Panama City Beach, FL',
	'Philadelphia, PA',
	'Rapid City, SD',
	'San Diego, CA',
	'St Augustine, FL',
	'St Louis, MO',
	'Wisconsin Dells, WI',
	'2 R/T domestic airline tickets on a discount airline using major market airports to be determined by SFM.  Can be added to any existing SFM vacation.',
];

const columns = [
	{ id: 'name', label: 'Vacations without airfare', minWidth: 400 },
	{ id: 'credits', label: 'Credits', minWidth: 100 },
];

const EditVacationCredits = () => {
	const [tableRows, setTableRows] = React.useState([]);
	const [vacationWithAirFareData, setVacationWithAirFareData] = React.useState(
		[]
	);
	const [specialtyVacationData, setSpecialtyVacationData] = React.useState([]);
	const [withAirFareRows, setWithAirFareRows] = React.useState([]);
	const [specialtyVacationRows, setSpecialtyVacationRows] = React.useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuthContext();

	const handleNameChange = (e) => {
		setTableRows((prev) => {
			return prev.map((row) => {
				if (row.name === e.target.name) {
					return { ...row, credits: customTextField(e.target.name, e.target.value) };
				}
				return row;
			});
		});
	};

	const customTextField = (key, value) => {
		return (
			<TextField
				inputProps={{
					style: { textAlign: 'center', fontSize: 13, fontWeight: 500 },
				}}
				variant="outlined"
				size="small"
				name={key}
				value={value}
				onChange={handleNameChange}
			></TextField>
		);
	};

	useEffect(() => {
		const getData = async () => {
			const colRef = collection(db, 'vacationCredits');
			const rows = [];
			const docs = await getDocs(colRef);
			docs.forEach((doc) => {
				const withoutAirFare = doc.data()['Vacations without airfare'];
				const withAirFare = doc.data()['Vacations with airfare'];
				const specialtyVacations = doc.data()['Specialty Vacations'];
				setVacationWithAirFareData(withAirFare);
				setSpecialtyVacationData(specialtyVacations);
				vacationObjectKeys.forEach((trip) => {
					rows.push(
						createCreditsTableRowData(
							trip,
							customTextField(trip, withoutAirFare[trip]),
							'normal'
						)
					);
				});
				// add manually subtitle rows in table by index
				rows.splice(
					0,
					0,
					createCreditsTableRowData(
						'Cruises, valid September 15 – December 15, minimum 120 days advance notice required, excluding holidays and annual events',
						'',
						'gray'
					)
				);
				rows.splice(
					3,
					0,
					createCreditsTableRowData(
						'Cruises, valid May 1 – September 30, minimum 120 days advance notice required, excluding July and holidays and annual events',
						'',
						'gray'
					)
				);
				rows.splice(
					5,
					0,
					createCreditsTableRowData(
						'Land packages, minimum 30 days advance notice required, excluding holidays and annual events',
						'',
						'gray'
					)
				);
				rows.splice(
					31,
					0,
					createCreditsTableRowData(
						'Domestic airfare from Atlanta, determined by SFM, to a major market airport, minimum 120 days advance notice required, excluding holidays and annual events',
						'',
						'gray'
					)
				);
				setTableRows(rows);
			});
		};
		getData();
	}, []);

	const addHistorialData = async (vacationData) => {
		// add historial data to firebase with userId, date, and email
		const colRed = collection(
			db,
			'vacationCredits',
			'nSBBdPB7MT4UsEGFEbC9',
			'changesHistorial'
		);
		const data = {
			...vacationData,
			date: new Date(),
			userId: user.uid,
			email: user.email,
		};
		await addDoc(colRed, data);
	};

	const updateCredtis = async () => {
		// get the 3 table ros data and update them on firebase
		try {
			const updatedVacationData = [];
			tableRows.forEach((row) => {
				if (row.type !== 'gray') {
					updatedVacationData.push({
						[row.name]: row.credits.props.value ? row.credits.props.value : '',
					});
				}
			});

			const updatedVacationAirFareData = [];
			withAirFareRows.forEach((row) => {
				if (row.type !== 'gray') {
					updatedVacationAirFareData.push({
						[row.name]: row.credits.props.value ? row.credits.props.value : '',
					});
				}
			});

			const updatedSpecialtyData = [];
			specialtyVacationRows.forEach((row) => {
				if (row.type !== 'gray') {
					updatedSpecialtyData.push({
						[row.name]: row.credits.props.value ? row.credits.props.value : '',
					});
				}
			});

			const data = {
				'Vacations without airfare': Object.assign(...updatedVacationData),
				'Vacations with airfare': Object.assign(...updatedVacationAirFareData),
				'Specialty Vacations': Object.assign(...updatedSpecialtyData),
			};

			// set the data to firebase document by id, because we only have one document
			const document = doc(db, 'vacationCredits', 'nSBBdPB7MT4UsEGFEbC9');

			await setDoc(document, data);
			await addHistorialData(data);

			enqueueSnackbar('Credits Updated', { variant: 'success' });
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Error updating credits, please try again!', {
				variant: 'error',
			});
		}
	};
	return (
		<>
			{' '}
			{tableRows.length > 0 && vacationWithAirFareData && specialtyVacationData ? (
				<Paper
					sx={{
						width: '100%',
						overflow: 'hidden',
						alignContent: 'center',
						alignItems: 'center',
						alignSelf: 'center',
					}}
				>
					<Grid container justifyContent="center">
						<TableContainer sx={{ maxWidth: 700, maxHeight: 550 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												sx={{
													backgroundColor: '#6C6767',
													color: 'white',
													padding: '5px 0px 5px 10px',
													borderLeft: '1px solid black',
													'&:last-child': {
														borderRight: '1px solid black',
														paddingLeft: '40px',
													},
												}}
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{tableRows.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.name}
												sx={{
													backgroundColor: row.type === 'normal' ? 'white' : '#D2D2D2',
												}}
											>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell
															sx={{
																fontSize: 12,
																borderBottom: '1px solid black',
																borderLeft: '1px solid black',
																'&:last-child': {
																	borderRight: '1px solid black',
																},
															}}
															key={column.id}
															align={column.align}
														>
															{column.format && typeof value === 'number'
																? column.format(value)
																: value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
							<VacationWithAirFareTable
								object={vacationWithAirFareData}
								tableRows={withAirFareRows}
								setTableRows={setWithAirFareRows}
							></VacationWithAirFareTable>
							<SpecialtyVacationTable
								object={specialtyVacationData}
								tableRows={specialtyVacationRows}
								setTableRows={setSpecialtyVacationRows}
							></SpecialtyVacationTable>
						</TableContainer>
						<Button
							variant="contained"
							color="success"
							sx={{ maxHeight: 40, marginLeft: 2 }}
							onClick={updateCredtis}
						>
							Update Credits
						</Button>
					</Grid>
				</Paper>
			) : (
				''
			)}
		</>
	);
};

export default EditVacationCredits;
