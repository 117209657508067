/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SigningPage = () => {
	const { state } = useLocation();
	// const params = useParams();
	// const { documentHash } = params;

	useEffect(() => {
		const getSign = async () => {
			// const documentHashh = documentHash;
			const xodoSignURL = state?.signURL;

			if (!xodoSignURL) {
				console.error('No signing URL provided');
				return;
			}
			window.location.href = xodoSignURL;
		};
		getSign();
	}, []);
};

export default SigningPage;
