/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import JSZip from 'jszip';
import { baseURL } from '../../src/utils/index.js';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
	openContractSignedView,
	onClose,
	documentId,
	service,
	setEsignDocumentId,
	setService,
}) {
	const [scrollHere, setScroll] = React.useState(0);
	const [loading, setLoading] = React.useState(false);
	const [isFetched, setIsFetched] = React.useState(false);

	useEffect(() => {
		const getData = async () => {
			try {
				setLoading(true);
				if (service === 'anvil') {
					const blob = await fetch(baseURL + '/fetchSignedDocs', {
						method: 'POST',
						body: JSON.stringify({
							documentGroupEid: documentId,
						}),
					});
					// unzip the file
					const zip = new JSZip();
					// get the blob
					const response = await blob.json();
					// if the response is 200, unzip the file
					if (response.statusCode === 200) {
						// unzip the file
						const unzipped = await zip.loadAsync(response.data.data);
						let blob = new Blob(
							[unzipped.files['blob.pdf']._data.compressedContent],
							{
								type: 'application/pdf',
							}
						);
						// create a url for the blob
						let pdfurl = window.URL.createObjectURL(blob);
						window.open(pdfurl);
						setLoading(false);
						onClose();
						setEsignDocumentId(null);
						setService(null);
					}
				} else if (service === 'xodo') {
					const data = {
						documentHash: documentId,
					};
					const response = await axios.post(
						baseURL + '/downloadDocumentXodoURL',
						data,
						{ responseType: 'arraybuffer' }
					);

					if (response.status === 200) {
						const blob = new Blob([response.data], {
							type: 'application/pdf',
						});
						const url = window.URL.createObjectURL(blob);
						window.open(url);
						setLoading(false);
						onClose();
						setEsignDocumentId(null);
						setService(null);
					}
				}
			} catch (error) {
				console.log(error);
			}
		};
		if (documentId !== null && !isFetched) {
			getData();
			setIsFetched(true);
		}
	}, []);

	useEffect(() => {
		if (openContractSignedView) {
			const y = window.pageYOffset;
			setScroll(y);
		}
	}, [openContractSignedView]);

	const handleCloseDeal = () => {
		onClose();
		setTimeout(() => {
			window.scrollTo({ top: scrollHere, behavior: 'auto' });
		}, 1);
	};

	return (
		<Dialog
			open={openContractSignedView}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDeal}
			aria-describedby="alert-dialog-slide-description"
			sx={{
				'& .MuiDialog-paper': {
					overflowY: 'hidden',
					backgroundColor: 'white',
				},
			}}
		>
			<DialogTitle sx={{ pb: 2 }}>Loading...</DialogTitle>
		</Dialog>
	);
}
