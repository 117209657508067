import React from 'react';
import { useTheme, ThemeProvider } from '@mui/material';
import { MainBox, getTheme } from '../../utils/themes';
import MUIDataTable from 'mui-datatables';
import { optionsTableRewards } from './utils';

const ListRewardCards = ({ orderCardsRecords }) => {
	const theme = useTheme();
	const columns = [
		{
			name: 'dealNumber',
			label: 'Deal #',
		},

		{
			name: 'cardDueDate',
			label: 'Card Due Date',
		},
		{
			name: 'balance',
			label: 'balance',
			options: {
				filter: true,
				sort: false,
				customBodyRender: (value) => {
					return value ? `$ ${value}` : '$ 0';
				},
			},
		},
		{
			name: 'numberOfCards',
			label: 'Number Of Cards',
		},
		{
			name: 'denomination',
			label: 'Denomination',
		},
		{
			name: 'status',
			label: 'Status',
		},
	];

	return (
		<MainBox
			sx={{
				boxShadow: (theme) => theme.shadows[4],
				borderRadius: 2,
				paddingX: 1.2,
				marginX: -1,
			}}
		>
			<ThemeProvider theme={getTheme(theme)}>
				<MUIDataTable
					data={orderCardsRecords || []}
					columns={columns}
					options={{
						...optionsTableRewards,
						selectToolbarPlacement: 'replace',
						responsive: 'standard',
						selectableRows: 'none',
					}}
				></MUIDataTable>
			</ThemeProvider>
		</MainBox>
	);
};
export default ListRewardCards;
