/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
	usePDF,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	PDFViewer,
	Image,
} from '@react-pdf/renderer';
import {
	formatDate,
	numberWithCommas,
	formatDateWithSlash,
	sendEmailMessage,
	sendTextMessage,
	handleEquitableDayparts,
	agreementNumberId,
} from '../../utils';
import { db, storage } from '../../firebase/config';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import {
	addDoc,
	getDoc,
	collection,
	serverTimestamp,
	updateDoc,
	doc,
} from 'firebase/firestore';
import imageLogo from '../../assets/images/sunfunmedialogopdf.png';
import {
	valuePerDay,
	getValuesTable,
	getDayPart,
	isWeekendEmptyRows,
	getTotalReward,
	getCompanyInfo,
} from './utils/utils';
import { reusableEmailButton } from '../AQHCalculator/utils/utils';

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#FFF',
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	text: {
		fontSize: 14,
		textAlign: 'justify',
		margin: 5,
		// fontFamily: 'Times-Roman'
	},
	mainHeading: {
		textAlign: 'center',
		marginBottom: 12,
	},
	gridContainer: {
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
	},
	tableMain: {
		flexDirection: 'row',
		display: 'flex',
	},
	image: {
		width: 70,
		height: 100,
	},
	alignElementsEnd: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
	},
	textTitles: {
		fontSize: 12,
		textAlign: 'center',
		// fontWeight: 900
	},
	textContract: {
		fontSize: 11,
		textAlign: 'justify',
	},
	foot: {
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	table: {
		display: 'table',
		width: 'auto',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row',
	},
	tableCol: {
		borderStyle: 'solid',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableCell: {
		margin: 3,
		fontSize: 10,
		textAlign: 'left',
	},
	tableCellValues: {
		textAlign: 'center',
		padding: 3,
		fontSize: 10,
	},
	footer: {
		fontSize: 10,
		position: 'absolute',
		bottom: 17,
		left: 10,
		right: 10,
	},
	footerInfo: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
});
const emailsRef = collection(db, 'emails');

// empty rows
const EmptyRows = () => {
	return (
		<>
			<View style={styles.tableRow}>
				<View style={{ ...styles.tableCol, width: '90%' }}>
					<Text style={styles.tableCell}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: '10%' }}>
					<Text style={styles.tableCellValues} />
				</View>
			</View>
		</>
	);
};

const EmptyRowsRewards = () => {
	return (
		<View style={styles.tableRow}>
			<View style={{ ...styles.tableCol, width: '10%' }}>
				<Text style={styles.tableCellValues}> </Text>
			</View>
			<View style={{ ...styles.tableCol, width: '70%' }}>
				<Text style={styles.tableCell}> </Text>
			</View>
			<View style={{ ...styles.tableCol, width: '20%' }}>
				<Text style={styles.tableCellValues}> </Text>
			</View>
		</View>
	);
};

//empty rows for dayparts
const EmptyRowsDayParts = (obj) => {
	//if have weekend dayparts then show
	const showWeekend = isWeekendEmptyRows(obj);
	return (
		<>
			<View style={styles.tableRow}>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '15%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '15%' : '12%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '14%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '14%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '14%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '14%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				<View style={{ ...styles.tableCol, width: !showWeekend ? '14%' : '11%' }}>
					<Text style={styles.tableCellValues}> </Text>
				</View>
				{showWeekend ? (
					<>
						<View style={{ ...styles.tableCol, width: '11%' }}>
							<Text style={styles.tableCellValues}> </Text>
						</View>
						<View style={{ ...styles.tableCol, width: '11%' }}>
							<Text style={styles.tableCellValues}> </Text>
						</View>
					</>
				) : (
					<></>
				)}
			</View>
		</>
	);
};

// titles for the table
const TableTitles = (obj) => {
	const showWeekend = isWeekendEmptyRows(obj);
	return (
		<>
			<View style={styles.tableRow}>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '15%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Station
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '15%' : '12%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Daypart
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '14%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Mon
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '14%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Tue
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '14%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Wed
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '14%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Thurs
					</Text>
				</View>
				<View
					style={{
						...styles.tableCol,
						width: !showWeekend ? '14%' : '11%',
						backgroundColor: '#595959',
					}}
				>
					<Text style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}>
						Fri
					</Text>
				</View>
				{showWeekend ? (
					<>
						<View
							style={{ ...styles.tableCol, width: '11%', backgroundColor: '#595959' }}
						>
							<Text
								style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
							>
								Sat
							</Text>
						</View>
						<View
							style={{ ...styles.tableCol, width: '11%', backgroundColor: '#595959' }}
						>
							<Text
								style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
							>
								Sun
							</Text>
						</View>
					</>
				) : (
					<></>
				)}
			</View>
		</>
	);
};

// Create Document Component
const GeneratePDF = ({
	mainObj,
	user,
	show,
	print,
	proposalSend,
	proposalData,
	setProposalSend,
	showPdfPreview,
	setLoading,
	setOption,
	setContractSignUrl,
	setContractCreated,
	setContractUploaded,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	// get the company info through the function
	let companyInfo =
		mainObj?.selectedRowsAqh?.length > 0
			? getCompanyInfo(mainObj.selectedRowsAqh)
			: null;
	// get the airports
	let airports = mainObj?.airports || null;
	// get the rolloverr credits
	let rolloverCredits = mainObj?.rolloverCredits || null;

	const valueCells = () => {
		const newObject = getValuesTable(mainObj.getLiveTableData);
		let days = Object.keys(newObject).map((key, i) =>
			newObject[key].map((v, i) => v.dayPartName.split(' ')[0])
		);
		// flat the array
		const arrayDays = days.flatMap((v) => v);

		// check if the array has weekend days
		if (arrayDays.includes('MSu')) {
			return true;
		} else if (arrayDays.includes('SS')) {
			return true;
		} else if (arrayDays.includes('Sat')) {
			return true;
		} else if (arrayDays.includes('Sun')) {
			return true;
		} else {
			return false;
		}
	};

	const MainPage = (
		<Document>
			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.alignElementsEnd}>
							<Image src={imageLogo} style={styles.image} />
						</View>
						<Text style={{ ...styles.textTitles, paddingTop: 40 }}>
							Exchange of Goods Agreement
						</Text>
						<Text style={{ ...styles.textContract, paddingTop: 10 }}>
							This Exchange Agreement is made between Sun & Fun Media, LLC, hereafter
							SFM, and {companyInfo[0]?.company}, hereafter Client,{' '}
							{companyInfo[0]?.address}, {companyInfo[0]?.city},{' '}
							{companyInfo[0]?.state}, {companyInfo[0]?.zip}, which operates the
							station(s) included in this agreement.
						</Text>
						<Text style={{ ...styles.textContract, paddingTop: 10 }}>
							The parties agree to exchange the following goods:
						</Text>

						<View style={{ display: 'flex', flexDirection: 'row' }}>
							<Text
								style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 20 }}
							>
								1.{' '}
							</Text>
							<Text style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 5 }}>
								SFM will provide Client with the goods shown on Attachment A;
							</Text>
						</View>
						<View style={{ display: 'flex', flexDirection: 'row' }}>
							<Text
								style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 20 }}
							>
								2.{' '}
							</Text>
							<Text
								style={{
									...styles.textContract,
									paddingTop: 10,
									paddingLeft: 5,
									width: '95%',
								}}
							>
								Client will provide SFM with {numberWithCommas(mainObj?.grossWeekly)}{' '}
								gross weekly impressions by airing the Commercial Schedules shown on
								Attachment B, on a non-preemptible basis. Affidavits must be summited
								online within 7 days of airtime. Please do not mail affidavits. If a
								commercial is missed, Client will contact Premiere Networks within 1
								week to arrange a make-good either with the same spot or a new spot,
								based on Premiere’s guidance. If affidavits are not received within 30
								days, or missed commercials have not been made good, SFM reserves the
								right to suspend the delivery of the goods on Attachment A until the
								matter is resolved.
							</Text>
						</View>
						<Text style={{ ...styles.textContract, paddingTop: 15 }}>
							If this agreement is renewed for the same gross weekly impressions, or
							more, any remaining goods shall rollover on Client’s account. If this
							agreement is not renewed, any unused commercial time by SFM, or unordered
							goods by Client, shall be voided effective midnight on the last day of
							the commercial schedule shown on Attachment B.
						</Text>
						<Text style={{ ...styles.textContract, paddingTop: 15 }}>
							Client agrees that if a change in ownership or control of any station
							shown on Schedule B occurs, this agreement shall be assumed by the new
							owner or controlling party; however, SFM reserves the right to cancel
							this agreement if it does not desire to conduct business with the new
							owner or controlling party.
						</Text>
						<Text style={{ ...styles.textContract, paddingTop: 15 }}>
							Any dispute arising from this agreement is subject to the laws of the
							State of Florida and the exclusive jurisdiction and venue of the state
							courts located in Seminole County, Florida, and federal courts located in
							the Middle District of Florida. The prevailing party will be entitled to
							any and all attorney fees and costs arising from the matter.
						</Text>
						<View style={{ display: 'flex', flexDirection: 'row', paddingTop: 60 }}>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<Text style={{ ...styles.textContract, paddingTop: 10 }}>
									Name / Title
								</Text>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 10 }}
								>
									________________________
								</Text>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 70 }}
								>
									Date
								</Text>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 10 }}
								>
									__________________
								</Text>
							</View>
						</View>
						<Text style={{ ...styles.textContract, paddingTop: 5, paddingLeft: 70 }}>
							For Client
						</Text>
						<View style={{ display: 'flex', flexDirection: 'row', paddingTop: 30 }}>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<Text style={{ ...styles.textContract, paddingTop: 10 }}>
									Name / Title
								</Text>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 10 }}
								>
									________________________
								</Text>
							</View>
							<View style={{ display: 'flex', flexDirection: 'row' }}>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 70 }}
								>
									Date
								</Text>
								<Text
									style={{ ...styles.textContract, paddingTop: 10, paddingLeft: 10 }}
								>
									__________________
								</Text>
							</View>
						</View>
						<Text style={{ ...styles.textContract, paddingTop: 5, paddingLeft: 70 }}>
							For SFM
						</Text>
						<Text style={{ paddingTop: 10, textAlign: 'center', fontSize: 9 }}>
							Please sign above, initial the bottom of all other pages, and return all
							pages.
						</Text>
						<View style={{ display: 'flex', flexDirection: 'row' }}>
							<Text style={{ ...styles.textContract, paddingTop: 30 }}>
								SFM Agreement
							</Text>
							<View style={{ display: 'flex', flexDirection: 'column' }}>
								<Text
									style={{ ...styles.textContract, paddingTop: 27, paddingLeft: 10 }}
								>
									{mainObj?.agreementNumber || ''}
								</Text>
								<Text
									style={{
										...styles.textContract,
										marginTop: mainObj?.agreementNumber ? -9 : 5,
										paddingLeft: 10,
									}}
								>
									____________________
								</Text>
							</View>
						</View>
						<View
							style={{ ...styles.alignElementsEnd, ...styles.foot, paddingTop: 70 }}
						>
							<Text style={{ ...styles.textContract, textAlign: 'right' }}>
								Ph (407) 328-0505 | Fax (407) 328-0051
							</Text>
							<Text style={{ ...styles.textContract, textAlign: 'right' }}>
								1315 South International Parkway | Suite 1131 | Lake Mary, FL 32746
							</Text>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>

			{/* Page 2 */}

			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.header}>
							<View style={{ paddingTop: 30 }}>
								<Text style={styles.textContract}>Attachment A</Text>
								<Text style={styles.textContract}>Goods Provided by SFM</Text>
							</View>
							<Image src={imageLogo} style={styles.image} />
						</View>
						<Text style={{ ...styles.textContract, paddingBottom: 20 }}>
							SFM agrees to provide Client with the following:
						</Text>
						{/* Attachment tables */}
						{/* ------- Vacations credits -------*/}
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Quantity
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Vacation Credits
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Due Date
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}>
										Not valid for group travel or resale, shall only be used in
										conjunction with the stations listed on page 1, and travelers must 21
										or older. Not valid for holidays or annual events without advance
										authorization from SFM and additional credits will apply.
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj.dealCalculations[0].cogs > 0
											? mainObj.dealCalculations[0].cogs
											: 'N/A'}
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '70%' }}>
									<Text style={styles.tableCell}>New Vacation Credits</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '20%' }}>
									<Text style={styles.tableCellValues}>TBD by Client</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{rolloverCredits !== null ? rolloverCredits?.credits : 'N/A'}
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '70%' }}>
									<Text style={styles.tableCell}>Rollover Vacation Credits</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '20%' }}>
									<Text style={styles.tableCellValues}>
										{rolloverCredits !== null
											? formatDateWithSlash(rolloverCredits?.date)
											: ''}
									</Text>
								</View>
							</View>
							<EmptyRowsRewards />
						</View>
						{/* ------- Visa Reward Cards credits ------- */}
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Quantity
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Visa Reward Cards
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Due Date
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}>
										Visa Reward Cards must be used in full within 1 year from the date of
										issue. Any unused funds will expire after that.
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							{(mainObj?.rewards?.visa?.length || 0) > 0 ? (
								mainObj.rewards.visa.map((item, index) => {
									return (
										<View style={styles.tableRow} key={index}>
											<View style={{ ...styles.tableCol, width: '10%' }}>
												<Text style={styles.tableCellValues}>
													{item.quantity ? item.quantity : 'N/A'}
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '70%' }}>
												<Text style={styles.tableCell}>
													$ {item.value ? item.value : '0'} Cards
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '20%' }}>
												<Text style={styles.tableCellValues}>
													{item.date ? formatDateWithSlash(item.date) : ''}
												</Text>
											</View>
										</View>
									);
								})
							) : (
								<>
									<View style={styles.tableRow}>
										<View style={{ ...styles.tableCol, width: '10%' }}>
											<Text style={styles.tableCellValues}>N/A</Text>
										</View>
										<View style={{ ...styles.tableCol, width: '70%' }}>
											<Text style={styles.tableCell}>$ 0 Cards</Text>
										</View>
										<View style={{ ...styles.tableCol, width: '20%' }}>
											<Text style={styles.tableCellValues} />
										</View>
									</View>
								</>
							)}
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}> </Text>
								</View>
								<View style={{ ...styles.tableCol, width: '70%' }}>
									<Text style={styles.tableCell}>
										{(mainObj?.rewards?.visa?.length || 0) > 0
											? `Total $ ${numberWithCommas(getTotalReward(mainObj.rewards.visa))}`
											: ''}
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '20%' }}>
									<Text style={styles.tableCellValues} />
								</View>
							</View>
							<EmptyRowsRewards />
							{/* Amazon gift cards rows */}
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Quantity
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Amazon Gift Cards
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Due Date
									</Text>
								</View>
							</View>
							{(mainObj?.rewards?.amazon?.length || 0) > 0 ? (
								mainObj.rewards.amazon.map((item, index) => {
									return (
										<View style={styles.tableRow} key={index}>
											<View style={{ ...styles.tableCol, width: '10%' }}>
												<Text style={styles.tableCellValues}>
													{item.quantity ? item.quantity : 'N/A'}
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '70%' }}>
												<Text style={styles.tableCell}>
													$ {item.value ? item.value : '0'} Gifts Cards
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '20%' }}>
												<Text style={styles.tableCellValues}>
													{item.date ? formatDateWithSlash(item.date) : ''}
												</Text>
											</View>
										</View>
									);
								})
							) : (
								<View style={styles.tableRow}>
									<View style={{ ...styles.tableCol, width: '10%' }}>
										<Text style={styles.tableCellValues}>N/A</Text>
									</View>
									<View style={{ ...styles.tableCol, width: '70%' }}>
										<Text style={styles.tableCell}>$ 0 Gift Cards</Text>
									</View>
									<View style={{ ...styles.tableCol, width: '20%' }}>
										<Text style={styles.tableCellValues} />
									</View>
								</View>
							)}
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}> </Text>
								</View>
								<View style={{ ...styles.tableCol, width: '70%' }}>
									<Text style={styles.tableCell}>
										{(mainObj?.rewards?.amazon?.length || 0) > 0
											? `Total $ ${numberWithCommas(
													getTotalReward(mainObj.rewards.amazon)
											  )}`
											: ''}
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '20%' }}>
									<Text style={styles.tableCellValues} />
								</View>
							</View>
							<EmptyRowsRewards />
							{/* Billpay rows */}
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Quantity
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>Billpay</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Due Date
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '70%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}>
										To redeem your billpay, please send an estimate, quote, or written
										request to your SFM representative and accounting@sunfunmedia.com.
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '20%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							{(mainObj?.rewards?.billpay?.length || 0) > 0 ? (
								mainObj.rewards.billpay.map((item, index) => {
									return (
										<View style={styles.tableRow} key={index}>
											<View style={{ ...styles.tableCol, width: '10%' }}>
												<Text style={styles.tableCellValues}>
													{item.quantity ? item.quantity : 'N/A'}
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '70%' }}>
												<Text style={styles.tableCell}>
													$ {item.value ? item.value : '0'} installment
												</Text>
											</View>
											<View style={{ ...styles.tableCol, width: '20%' }}>
												<Text style={styles.tableCellValues}>
													{item.date ? formatDateWithSlash(item.date) : ''}
												</Text>
											</View>
										</View>
									);
								})
							) : (
								<View style={styles.tableRow}>
									<View style={{ ...styles.tableCol, width: '10%' }}>
										<Text style={styles.tableCellValues}>N/A</Text>
									</View>
									<View style={{ ...styles.tableCol, width: '70%' }}>
										<Text style={styles.tableCell}>$ 0 installment</Text>
									</View>
									<View style={{ ...styles.tableCol, width: '20%' }}>
										<Text style={styles.tableCellValues} />
									</View>
								</View>
							)}
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}> </Text>
								</View>
								<View style={{ ...styles.tableCol, width: '70%' }}>
									<Text style={styles.tableCell}>
										{(mainObj?.rewards?.amazon?.length || 0) > 0
											? `Total $ ${numberWithCommas(
													getTotalReward(mainObj.rewards.billpay)
											  )}`
											: ''}
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '20%' }}>
									<Text style={styles.tableCellValues} />
								</View>
							</View>
							<EmptyRowsRewards />
						</View>

						{/* Footer */}
						<View style={styles.footer}>
							<View style={styles.footerInfo}>
								<Text>{companyInfo[0]?.company}</Text>
								<Text>Agreement page 2 of 6</Text>
							</View>
							<View style={styles.footerInfo}>
								<Text>
									{companyInfo[0]?.city}, {companyInfo[0]?.state}; &nbsp;
									{mainObj.startDate && formatDateWithSlash(mainObj.startDate)} -{' '}
									{mainObj.endDate && formatDateWithSlash(mainObj.endDate)}
								</Text>
								<Text style={{ marginTop: 15 }}>Client Initials _______________</Text>
							</View>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>

			{/* Page 3  Credits per Vacations */}
			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.header}>
							<View style={{ paddingTop: 30 }}>
								<Text style={styles.textContract}>Attachment A</Text>
								<Text style={styles.textContract}>Goods Provided by SFM</Text>
							</View>
							<Image src={imageLogo} style={styles.image} />
						</View>
						<Text
							style={{ ...styles.textContract, paddingBottom: 5, textAlign: 'center' }}
						>
							Credits per Vacation
						</Text>
						{/* ------- Credits per Vacation -------*/}
						{/* ------- Vacations without airfare table -------*/}
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Vacations without airfare
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Credits
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Cruises, valid September 15 – December 15, minimum 120 days advance
										notice required, excluding holidays and annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>
										5-Day Carnival Cruise from Miami, FL
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'5-Day Carnival Cruise from Miami, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>
										5-Day Carnival Cruise from New Orleans, LA
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'5-Day Carnival Cruise from New Orleans, LA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Cruises, valid May 1 – September 30, minimum 120 days advance notice
										required, excluding July and holidays and annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>
										7-Day Carnival Alaskan Cruise from Seattle, WA
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'7-Day Carnival Alaskan Cruise from Seattle, WA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Land packages, minimum 30 days advance notice required, excluding
										holidays and annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Atlanta, GA</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Atlanta, GA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Biloxi, MS</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Biloxi, MS'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Breckenridge, CO</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Breckenridge, CO'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Chicago, IL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Chicago, IL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Dallas, TX</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Dallas, TX'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Daytona Beach, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Daytona Beach, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Gatlinburg, TN</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Gatlinburg, TN'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Jackson Hole, WY</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Jackson Hole, WY'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Key West, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Key West, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Las Vegas, NV</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Las Vegas, NV'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Los Angeles, CA</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Los Angeles, CA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Memphis, TN</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Memphis, TN'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Miami Beach, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Miami Beach, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Myrtle Beach, SC</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Myrtle Beach, SC'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Nashville, TN</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Nashville, TN'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>New Orleans, LA</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'New Orleans, LA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>New York, NY</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'New York, NY'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Orlando Attractions, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Orlando Attractions, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Panama City Beach, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Panama City Beach, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Philadelphia, PA</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Philadelphia, PA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Rapid City, SD</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Rapid City, SD'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>San Diego, CA</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'San Diego, CA'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>St Augustine, FL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'St Augustine, FL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>St Louis, MO</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'St Louis, MO'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Wisconsin Dells, WI</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'Wisconsin Dells, WI'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
						</View>

						{/* Footer */}
						<View style={styles.footer}>
							<View style={styles.footerInfo}>
								<Text>{companyInfo[0]?.company}</Text>
								<Text>Agreement page 3 of 6</Text>
							</View>
							<View style={styles.footerInfo}>
								<Text>
									{companyInfo[0]?.city}, {companyInfo[0]?.state}; &nbsp;
									{mainObj.startDate && formatDateWithSlash(mainObj.startDate)} -{' '}
									{mainObj.endDate && formatDateWithSlash(mainObj.endDate)}
								</Text>
								<Text style={{ marginTop: 15 }}>Client Initials _______________</Text>
							</View>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>

			{/* Page 4 */}
			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.header}>
							<View style={{ paddingTop: 30 }}>
								<Text style={styles.textContract}>Attachment A</Text>
								<Text style={styles.textContract}>Goods Provided by SFM</Text>
							</View>
							<Image src={imageLogo} style={styles.image} />
						</View>
						<Text
							style={{ ...styles.textContract, paddingBottom: 5, textAlign: 'center' }}
						>
							Credits per Vacation
						</Text>
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Vacations without airfare
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Credits
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Domestic airfare from {airports !== null ? airports : 'Atlanta'},
										determined by SFM, to a major market airport, minimum 120 days advance
										notice required, excluding holidays and annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>
										2 R/T domestic airline tickets on a discount airline using major
										market airports to be determined by SFM. Can be added to any existing
										SFM vacation.
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations without airfare']?.[
													'2 R/T domestic airline tickets on a discount airline using major market airports to be determined by SFM.  Can be added to any existing SFM vacation.'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
						</View>
						{/* ------- Vacations with airfare table -------*/}
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Vacations with airfare
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Credits
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Includes airfare for 2 from New York City, valid September 1 – March
										1, minimum 120 days advance notice required, excluding holidays and
										annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Amsterdam, NL</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Amsterdam, NL'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>London, UK</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'London, UK'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Paris, FR</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Paris, FR'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Rome, IT</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Rome, IT'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
						</View>
						<View style={styles.table}>
							{/* <View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Vacations with airfare
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Credits
									</Text>
								</View>
							</View> */}
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Includes airfare for 2 from Miami, valid September 1– March 1, minimum
										120 days advance notice required, excluding holidays and annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Aruba</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.['Aruba'] ||
											  'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Cancun, MX</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Cancun, MX'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Freeport, BS</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Freeport, BS'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Montego Bay, JM</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Montego Bay, JM'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Nassau, BS</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Nassau, BS'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Negril, JM</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Negril, JM'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
							<EmptyRows />
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Includes airfare for 2 from Los Angeles, valid September 1 – March 1,
										minimum 120 days advance notice required, excluding holidays and
										annual events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Waikiki Beach, HI </Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Vacations with airfare']?.[
													'Waikiki Beach, HI'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
							<EmptyRows />
						</View>
						{/* Footer */}
						<View style={styles.footer}>
							<View style={styles.footerInfo}>
								<Text>{companyInfo[0]?.company}</Text>
								<Text>Agreement page 4 of 6</Text>
							</View>
							<View style={styles.footerInfo}>
								<Text>
									{companyInfo[0]?.city}, {companyInfo[0]?.state}; &nbsp;
									{mainObj.startDate && formatDateWithSlash(mainObj.startDate)} -{' '}
									{mainObj.endDate && formatDateWithSlash(mainObj.endDate)}
								</Text>
								<Text style={{ marginTop: 15 }}>Client Initials _______________</Text>
							</View>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>

			{/* Page 5 */}
			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.header}>
							<View style={{ paddingTop: 30 }}>
								<Text style={styles.textContract}>Attachment A</Text>
								<Text style={styles.textContract}>Goods Provided by SFM</Text>
							</View>
							<Image src={imageLogo} style={styles.image} />
						</View>
						<Text
							style={{ ...styles.textContract, paddingBottom: 5, textAlign: 'center' }}
						>
							Credits per Vacation
						</Text>
						{/* ------- Specialty Vacations -------*/}
						<View style={styles.table}>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#595959',
									}}
								>
									<Text style={{ ...styles.tableCell, color: 'white' }}>
										Specialty Vacations
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#595959',
									}}
								>
									<Text
										style={{ ...styles.tableCell, color: 'white', textAlign: 'center' }}
									>
										Credits
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '90%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={{ ...styles.tableCell, textAlign: 'center' }}>
										Includes airfare for 2 from{' '}
										{airports !== null ? airports : 'St Louis'}, determined by SFM,
										minimum 90 days advance notice required, excluding holidays and annual
										events
									</Text>
								</View>
								<View
									style={{
										...styles.tableCol,
										width: '10%',
										backgroundColor: '#d9d9d9',
									}}
								>
									<Text style={styles.tableCell}></Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Daytona 500 Race Package</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Daytona 500 Race Package'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Deep Sea Fishing in the Bahamas</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Deep Sea Fishing in the Bahamas'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Disney Family Vacation</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Disney Family Vacation'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Get Out and Golf Package</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Get Out and Golf Package'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Live in Concert Your Core Artist</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Live in Concert Your Core Artist'
											  ] || 'TBD'
											: 'TBD'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Napa Valley Winery Package</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Napa Valley Winery Package'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>On Broadway New York City</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'On Broadway New York City'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Pebble Beach</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Pebble Beach'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Santa Fe Spa Couples Getaway</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Santa Fe Spa Couples Getaway'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Shop ‘til You Drop New York City</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Shop ‘til You Drop New York City'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>Taste of New Orleans</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues}>
										{mainObj?.dealCalculations[0]?.cogs > 0
											? mainObj?.vacationCredits?.['Specialty Vacations']?.[
													'Taste of New Orleans'
											  ] || 'N/A'
											: 'N/A'}
									</Text>
								</View>
							</View>
							<EmptyRows />
							<EmptyRows />
							<View style={styles.tableRow}>
								<View style={{ ...styles.tableCol, width: '90%' }}>
									<Text style={styles.tableCell}>
										Credit quantities for all vacations are estimates. The final amount
										charged will be determined after the trip has been booked.
									</Text>
								</View>
								<View style={{ ...styles.tableCol, width: '10%' }}>
									<Text style={styles.tableCellValues} />
								</View>
							</View>
							<EmptyRows />
							<EmptyRows />
							<EmptyRows />
						</View>
						{/* Footer */}
						<View style={styles.footer}>
							<View style={styles.footerInfo}>
								<Text>{companyInfo[0]?.company}</Text>
								<Text>Agreement page 5 of 6</Text>
							</View>
							<View style={styles.footerInfo}>
								<Text>
									{companyInfo[0]?.city}, {companyInfo[0]?.state}; &nbsp;
									{mainObj.startDate && formatDateWithSlash(mainObj.startDate)} -{' '}
									{mainObj.endDate && formatDateWithSlash(mainObj.endDate)}
								</Text>
								<Text style={{ marginTop: 15 }}>Client Initials _______________</Text>
							</View>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>

			{/* Page 6 */}
			<Page size="A4" style={styles.page}>
				{mainObj ? (
					<View style={styles.section}>
						<View style={styles.header}>
							<View style={{ paddingTop: 30 }}>
								<Text style={styles.textContract}>Attachment B</Text>
								<Text style={styles.textContract}>Goods Provided by Client</Text>
							</View>
							<Image src={imageLogo} style={styles.image} />
						</View>

						<View style={{ paddingTop: 20 }} />
						<View style={styles.table}>
							{/* table about new deal */}
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '100%',
										backgroundColor: '#d9d9d9',
										flexDirection: 'column',
										display: 'flex',
										alignItems: 'flex-start',
									}}
								>
									<Text style={{ fontSize: 10, paddingLeft: 5, paddingTop: 2 }}>
										Client agrees to provide SFM with goods in the form of{' '}
										{numberWithCommas(mainObj?.grossWeekly)} gross weekly impressions for{' '}
									</Text>
									<Text style={{ fontSize: 10, paddingLeft: 5, paddingBottom: 2 }}>
										{parseInt(mainObj?.noOfWeeks, 10)} weeks delivered via the schedule(s)
										below.
									</Text>
								</View>
							</View>
							{/* table titles */}
							<TableTitles obj={getValuesTable(mainObj.getLiveTableData)} />
							{/* table values */}
							{Object.keys(getValuesTable(mainObj.getLiveTableData)).map((key, i) => (
								<View key={i}>
									{getValuesTable(mainObj.getLiveTableData)[key].map((v, i) => (
										<View key={i} style={styles.tableRow}>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '15%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>{v.station || ''}</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '15%' : '12%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{handleEquitableDayparts(v.dayPartName)}
												</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '14%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{getDayPart(v.dayPartName) === 'MF' ||
													getDayPart(v.dayPartName) === 'MSu'
														? valuePerDay(v.noOfSpots)
														: ''}
												</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '14%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{getDayPart(v.dayPartName) === 'MF' ||
													getDayPart(v.dayPartName) === 'MSu'
														? valuePerDay(v.noOfSpots)
														: ''}
												</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '14%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{getDayPart(v.dayPartName) === 'MF' ||
													getDayPart(v.dayPartName) === 'MSu'
														? valuePerDay(v.noOfSpots)
														: ''}
												</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '14%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{getDayPart(v.dayPartName) === 'MF' ||
													getDayPart(v.dayPartName) === 'MSu'
														? valuePerDay(v.noOfSpots)
														: ''}
												</Text>
											</View>
											<View
												style={{
													...styles.tableCol,
													width: valueCells() === false ? '14%' : '11%',
												}}
											>
												<Text style={styles.tableCellValues}>
													{getDayPart(v.dayPartName) === 'MF' ||
													getDayPart(v.dayPartName) === 'MSu'
														? valuePerDay(v.noOfSpots)
														: ''}
												</Text>
											</View>
											{valueCells() ? (
												<>
													<View style={{ ...styles.tableCol, width: '11%' }}>
														<Text style={styles.tableCellValues}>
															{getDayPart(v.dayPartName) === 'SS' ||
															getDayPart(v.dayPartName) === 'Sat' ||
															getDayPart(v.dayPartName) === 'MSu'
																? valuePerDay(v.noOfSpots)
																: ''}
														</Text>
													</View>
													<View style={{ ...styles.tableCol, width: '11%' }}>
														<Text style={styles.tableCellValues}>
															{getDayPart(v.dayPartName) === 'SS' ||
															getDayPart(v.dayPartName) === 'Sun' ||
															getDayPart(v.dayPartName) === 'MSu'
																? valuePerDay(v.noOfSpots)
																: ''}
														</Text>
													</View>
												</>
											) : (
												<> </>
											)}
										</View>
									))}
									<EmptyRowsDayParts obj={getValuesTable(mainObj.getLiveTableData)} />
									<EmptyRowsDayParts obj={getValuesTable(mainObj.getLiveTableData)} />
								</View>
							))}
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '100%',
										backgroundColor: '#d9d9d9',
										flexDirection: 'row',
										display: 'flex',
									}}
								>
									<Text style={{ ...styles.tableCellValues, paddingLeft: 5 }}>
										Start Date : {mainObj.startDate && formatDate(mainObj.startDate)}
									</Text>
									<Text style={{ ...styles.tableCellValues, paddingLeft: 10 }}>
										End Date : {mainObj.endDate && formatDate(mainObj.endDate)}
									</Text>
								</View>
							</View>
							<View style={styles.tableRow}>
								<View
									style={{
										...styles.tableCol,
										width: '100%',
										backgroundColor: '#d9d9d9',
										flexDirection: 'column',
										display: 'flex',
										alignItems: 'flex-start',
									}}
								>
									<Text style={{ fontSize: 10, paddingLeft: 5, paddingTop: 2 }}>
										each :60 scheduled with one :60 or two :30s with a minimum of 30
										minutes of separation{' '}
									</Text>
									<Text style={{ fontSize: 10, paddingLeft: 5, paddingBottom: 2 }}>
										* equitable distribution required
									</Text>
								</View>
							</View>
						</View>
						{/* table about bridge deal */}

						{mainObj?.bridgeDeal ? (
							<>
								<View style={{ paddingTop: 15, paddingBottom: 15 }}>
									<Text style={styles.textContract}>Bridge Deal</Text>
								</View>
								<View style={styles.table}>
									{/* table titles */}
									<TableTitles
										obj={getValuesTable(mainObj?.bridgeDeal?.getLiveTableData)}
									/>
									{/* table values */}
									{Object.keys(
										getValuesTable(mainObj?.bridgeDeal?.getLiveTableData)
									).map((key, i) => (
										<View key={i}>
											{getValuesTable(mainObj?.bridgeDeal?.getLiveTableData)[key].map(
												(v, i) => (
													<View key={i} style={styles.tableRow}>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '15%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>{v.station || ''}</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '15%' : '12%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{handleEquitableDayparts(v.dayPartName)}
															</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '14%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{getDayPart(v.dayPartName) === 'MF' ||
																getDayPart(v.dayPartName) === 'MSu'
																	? valuePerDay(v.noOfSpots)
																	: ''}
															</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '14%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{getDayPart(v.dayPartName) === 'MF' ||
																getDayPart(v.dayPartName) === 'MSu'
																	? valuePerDay(v.noOfSpots)
																	: ''}
															</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '14%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{getDayPart(v.dayPartName) === 'MF' ||
																getDayPart(v.dayPartName) === 'MSu'
																	? valuePerDay(v.noOfSpots)
																	: ''}
															</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '14%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{getDayPart(v.dayPartName) === 'MF' ||
																getDayPart(v.dayPartName) === 'MSu'
																	? valuePerDay(v.noOfSpots)
																	: ''}
															</Text>
														</View>
														<View
															style={{
																...styles.tableCol,
																width: valueCells() === false ? '14%' : '11%',
															}}
														>
															<Text style={styles.tableCellValues}>
																{getDayPart(v.dayPartName) === 'MF' ||
																getDayPart(v.dayPartName) === 'MSu'
																	? valuePerDay(v.noOfSpots)
																	: ''}
															</Text>
														</View>
														{valueCells() ? (
															<>
																<View style={{ ...styles.tableCol, width: '11%' }}>
																	<Text style={styles.tableCellValues}>
																		{getDayPart(v.dayPartName) === 'SS' ||
																		getDayPart(v.dayPartName) === 'Sat' ||
																		getDayPart(v.dayPartName) === 'MSu'
																			? valuePerDay(v.noOfSpots)
																			: ''}
																	</Text>
																</View>
																<View style={{ ...styles.tableCol, width: '11%' }}>
																	<Text style={styles.tableCellValues}>
																		{getDayPart(v.dayPartName) === 'SS' ||
																		getDayPart(v.dayPartName) === 'Sun' ||
																		getDayPart(v.dayPartName) === 'MSu'
																			? valuePerDay(v.noOfSpots)
																			: ''}
																	</Text>
																</View>
															</>
														) : (
															<> </>
														)}
													</View>
												)
											)}
											<EmptyRowsDayParts
												obj={getValuesTable(mainObj?.bridgeDeal?.getLiveTableData)}
											/>
											<EmptyRowsDayParts
												obj={getValuesTable(mainObj?.bridgeDeal?.getLiveTableData)}
											/>
										</View>
									))}
									<View style={styles.tableRow}>
										<View
											style={{
												...styles.tableCol,
												width: '100%',
												backgroundColor: '#d9d9d9',
												flexDirection: 'row',
												display: 'flex',
											}}
										>
											<Text style={styles.tableCellValues}>
												Start Date :{' '}
												{mainObj?.bridgeDeal?.startDate &&
													formatDate(mainObj?.bridgeDeal?.startDate)}
											</Text>
											<Text style={{ ...styles.tableCellValues, paddingLeft: 10 }}>
												End Date :{' '}
												{mainObj?.bridgeDeal?.endDate &&
													formatDate(mainObj?.bridgeDeal?.endDate)}
											</Text>
										</View>
									</View>
									<View style={styles.tableRow}>
										<View
											style={{
												...styles.tableCol,
												width: '100%',
												backgroundColor: '#d9d9d9',
												flexDirection: 'column',
												display: 'flex',
												alignItems: 'flex-start',
											}}
										>
											<Text style={{ fontSize: 10, paddingLeft: 5, paddingTop: 2 }}>
												each :60 scheduled with one :60 or two :30s with a minimum of 30
												minutes of separation{' '}
											</Text>
											<Text style={{ fontSize: 10, paddingLeft: 5, paddingBottom: 2 }}>
												* equitable distribution required
											</Text>
										</View>
									</View>
								</View>
							</>
						) : (
							''
						)}

						{/* Footer */}
						<View style={styles.footer}>
							<View style={styles.footerInfo}>
								<Text>{companyInfo[0]?.company}</Text>
								<Text>Agreement page 6 of 6</Text>
							</View>
							<View style={styles.footerInfo}>
								<Text>
									{companyInfo[0]?.city}, {companyInfo[0]?.state}; &nbsp;
									{mainObj.startDate && formatDateWithSlash(mainObj.startDate)} -{' '}
									{mainObj.endDate && formatDateWithSlash(mainObj.endDate)}
								</Text>
								<Text style={{ marginTop: 15 }}>Client Initials _______________</Text>
							</View>
						</View>
					</View>
				) : (
					<View style={styles.section}>
						<Text style={styles.mainHeading}>No data found!</Text>
					</View>
				)}
			</Page>
		</Document>
	);

	const [fileBlob, setBlob] = useState(null);
	const [instance, updateInstance] = usePDF({ document: MainPage });

	useEffect(() => {
		instance.blob && setBlob(instance.blob);
	}, [instance.blob]);

	useEffect(() => updateInstance(), [mainObj]);

	if (showPdfPreview) {
		return (
			<PDFViewer style={{ width: '100%', height: '100vh' }}>{MainPage}</PDFViewer>
		);
	}
};
export default GeneratePDF;
