import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { db, storage } from '../../../firebase/config'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { CircularProgress, TextField, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
    UploadFile as UploadFileIcon,
    DeleteOutlineIcon
} from '@mui/icons-material';
import { useSnackbar } from 'notistack'
import { isFinite } from 'lodash';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadNewComponent({ open, setOpen, user }) {
    const [name, setName] = React.useState("");
    const [noOfEditRows, setNoOfEditRows] = React.useState(1);
    const [loading, setLoading] = React.useState(false);
    const [fileLocalState, setFileLocalState] = React.useState(null)
    const [dltFiles, setDltFiles] = React.useState([])
    const [fileName, setFileName] = React.useState("");
    const { enqueueSnackbar } = useSnackbar()
    const [isUploading, setIsUploading] = React.useState(false)
    const [templateUrl, setTemplateURL] = React.useState(null)
    const [progress, setProgress] = React.useState(0)

    React.useEffect(() => {
        if (fileLocalState) {
            const { name } = fileLocalState
            let fileName = (name.length > 20 ? `${name.slice(0, 29)}...${name.slice(name.lastIndexOf("."), name.length)}` : name)
            setFileName(fileName)
        }
    }, [fileLocalState])


    const handleSave = async () => {
        setLoading(true)
        if (!fileLocalState) { return enqueueSnackbar("No file Selected!", { variant: "error" }) }
        uploadFile(fileLocalState)
    }

    const uploadFile = (file) => {
        if (!file) return;
        const storageRef = ref(storage, `templates/${Date.now()}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            (error) => {
                setIsUploading(false)
                alert(error);
                setLoading(false)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    setIsUploading(false)
                    setTemplateURL(downloadURL)
                    try {
                        const payload = { modifiedDate: serverTimestamp(), uid: user.uid, email: user.email, title: name, url: downloadURL }
                        const docRef = collection(db, 'templates')
                        await addDoc(docRef, payload)
                        enqueueSnackbar("Template Uploaded!", { variant: "success" })
                        setOpen(false)
                        setName("")
                        setFileLocalState(null)
                        setFileName("")
                        setLoading(false)
                    } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error" })
                        console.log(error)
                        setLoading(false)
                    }
                });
            }
        );

    }

    return (
        <div>
            <Tooltip title="Upload New" placement='bottom'>
                <IconButton color='primary' size="large"
                    disabled={noOfEditRows === 0}
                    onClick={() => setOpen(true)}>
                    <UploadFileIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    "& .MuiDialog-paper": {
                        minWidth: "340px",
                    }
                }}
            >
                <DialogTitle>Upload Template</DialogTitle>
                <DialogContent>
                    <Box sx={{
                        my: 1, "& >*": {
                            my: 1
                        }
                    }}>
                        <TextField
                            label={`Title`}
                            variant='outlined'
                            size='small'
                            name={"name"}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            fullWidth
                        />
                    </Box>
                    <Box className='' sx={{ display: !(fileName.length > 19) && "flex", gap: 1, alignItems: "center" }}>
                        <Button
                            component="label"
                            variant="contained"
                            color='primary'
                            size="small"
                            startIcon={<UploadFileIcon />}
                            sx={{
                                boxShadow: " 0px 4px 4px rgb(0 0 0 / 25%)",
                                borderRadius: 8,
                                textTransform: "inherit",
                            }}
                        >
                            Upload File
                            <input type="file" accept=".pdf"
                                hidden onChange={(e) => {
                                    if (!e.target.files) {
                                        return;
                                    }
                                    const file = e.target.files[0];
                                    const { name } = file;

                                    const reader = new FileReader();
                                    reader.onload = (evt) => {
                                        if (!evt?.target?.result) {
                                            return;
                                        }
                                        const { result } = evt.target;
                                        // handleFileUpload()
                                    }
                                    setFileLocalState(file)

                                }} />
                        </Button>
                        {fileLocalState && <Typography variant='body2' sx={{ fontWeight: "bold", p: (fileName.length > 19) && 1, maxWidth: (fileName.length > 19) ? "300px" : "150px" }}>{fileName || fileLocalState.name}</Typography>}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        setFileLocalState(null)
                        setFileName("")
                        setName("")
                        setOpen(false)
                    }}>Close</Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        startIcon={loading && <CircularProgress size={18} />}
                        disabled={loading || (!name || !fileLocalState)}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
