import React from 'react';
import { Typography } from '@mui/material';
import { MainBox } from './utils/themes';
import ListRewardCards from './components/RewardCards/ListRewardCards';
import useRewardCards from './hooks/useRewardCards';
import Search from './components/RewardCards/SearchRewardCards';

const RewardCards = () => {
	// Get the order cards records
	const { orderCardsRecords, setFilter } = useRewardCards();

	return (
		<MainBox>
			<Typography variant="h4">Reward Cards</Typography>
			<Search setFilter={setFilter}></Search>
			<ListRewardCards orderCardsRecords={orderCardsRecords} />
		</MainBox>
	);
};
export default RewardCards;
