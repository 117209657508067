/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	doc,
	getDoc,
	updateDoc,
	serverTimestamp,
	setDoc,
} from 'firebase/firestore';
import { Typography, CircularProgress, IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useCollection } from '../../hooks/useCollection';
import { db } from '../../firebase/config';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import axios from 'axios';
import { baseURL } from '../../utils';
import { format } from 'date-fns';
import LoadingView from '../../components/LoadingSign';
import ClientConfirmationPage from './components/ClientConfirmationPage';

const LooksGood = () => {
	const navigate = useNavigate();
	const params = useParams();
	const { inviterId, inviteeEmail, emailId } = params;
	const { documents: contract } = useCollection('contracts', [
		'emailId',
		'==',
		emailId,
	]);
	const { documents: esigns } = useCollection('esigns', [
		'emailId',
		'==',
		emailId,
	]);
	const [email, setEmail] = useState(null);
	const [loading, setLoading] = useState(null);
	const [esignCompleted, setEsignCompleted] = useState(null);
	const hostEnv = window.location.hostname === 'localhost' ? 'dev' : 'prod';
	const [signedDocument, setSignedDocument] = useState(null);
	const [loadingViewSign, setLoadingViewSign] = useState(false);
	const [sentToSign, setSentToSign] = useState(false); // by default is false
	const [esignData, setEsignData] = useState(null);
	const [contractLoaded, setContractLoaded] = useState(false);
	const [esignExists, setEsignExists] = useState(false);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const status = urlParams.get('status');

	// get the email data
	useEffect(() => {
		if (emailId) {
			const getEmail = async () => {
				const docRef = doc(db, 'emails', emailId);
				const emailSnap = await getDoc(docRef);
				if (emailSnap.exists()) {
					setEmail(emailSnap.data());
				}
			};
			getEmail();
		}
	}, []);

	// Check if the document is signed in xodo sign
	useEffect(() => {
		const checkContractDoc = async () => {
			try {
				if (contract[0].documentHash) {
					const documentHash = contract[0].documentHash;
					// get status of the document

					const data = {
						documentHash: documentHash,
					};
					// get the status of the document from the server
					const resp = await axios.post(baseURL + '/getDocumentXodoStatus', data);

					if (resp.status === 200) {
						const esignInfo = resp.data;
						if (
							esignInfo &&
							(esignInfo[1].signerStatus === 'partial' ||
								esignInfo[1].signerStatus === 'completed')
						) {
							setEsignData(resp.data);
						} else {
							setEsignData(null);
						}
					}
				}
			} catch (error) {
				console.error('Error checking signed document status:', error);
			}
			// validate if the contract is loaded
			setContractLoaded(true);
		};
		if (contract && contract.length > 0) {
			checkContractDoc();
		}
	}, [contract]);

	// send the client to process e-sign
	const handleProcessSign = async () => {
		try {
			setLoading(true);
			if (status === 'signed' && esigns.length > 0) {
				setLoadingViewSign(false);
				const esignDocs = esigns[0]?.data;
				setEsignCompleted(esignDocs);
			} else if (status === 'signed' && esigns.length === 0) {
				setLoadingViewSign(true);
			} else {
				if (
					!status &&
					contract[0].documentHash &&
					contract[0].embeddedXodoUrl &&
					esigns.length === 0
				) {
					// redirect to the e-sign page
					window.location.href = contract[0].embeddedXodoUrl;
				} else if (
					!status &&
					!contract[0].documentHash &&
					!contract[0].embeddedXodoUrl &&
					esigns.length === 0
				) {
					// create a object to send to xodo
					const data = {
						firstName: email.receiver_fname,
						lastName: email.receiver_lname,
						email: inviteeEmail,
						date: format(new Date(), 'MM-dd-yyyy'),
						contractTitle: contract[0].contractName,
						contractPdfURL: contract[0].url,
						redirectURL: `${window.location.origin}/${inviterId}/${inviteeEmail}/${emailId}?status=signed`,
						clientTitle: contract[0].receiverTitle,
						hostEnv: hostEnv,
						finalPage: contract[0].pages,
					};
					// create the document in xodo
					const resp = await axios.post(baseURL + '/createDocumentXodo', data);

					const payloadNoti = {
						title: `E-Sign Requested!`,
						message: `${email.receiver_fname} ${email.receiver_lname} has confirmed quote and requested esign`,
						sender_id: inviterId,
						receiver_id: inviterId,
						seen: false,
						createdAt: serverTimestamp(),
					};
					const notiRef = doc(db, 'notifications', emailId);
					const notiSnap = await getDoc(notiRef);
					if (!notiSnap.exists()) {
						await setDoc(notiRef, payloadNoti);
					} else {
						await updateDoc(notiRef, payloadNoti);
					}

					if (resp.status === 200) {
						// update the contract with documentHash
						await updateDoc(doc(db, 'contracts', contract[0].id), {
							documentHash: resp.data.documentHash,
							embeddedXodoUrl: resp.data.embeddedSigningUrl,
						});
						// redirect to the e-sign page
						navigate(resp.data.url, {
							state: {
								signURL: resp.data.embeddedSigningUrl,
								redirectURL: resp.data.redirectURL,
							},
						});
					}
				} else if (!status && esigns.length > 0) {
					const esignDocs = esigns[0]?.data;
					setEsignCompleted(esignDocs);
				}
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	// Check if the document is signed and create esign data in the esign collection
	useEffect(() => {
		if (!contractLoaded) return;
		const handlePostEsignAndCheckSignedDoc = async () => {
			// post the esign data to the esign collection
			const postEsign = async (esignData) => {
				// get esign data from condition above
				const esignDocs = esignData;

				// check if the document is signed
				if (esignDocs && esignDocs.length) {
					if (esignDocs[3].documentGroupStatus === 'partial') {
						// if the contract is not signed, set the data to the esign collection
						const xodoRef = doc(db, 'esigns', emailId);
						const xodoSnap = await getDoc(xodoRef);
						if (!xodoSnap.exists()) {
							// set the data to esignCompleted state
							setEsignCompleted({
								data: esignDocs,
								emailId: emailId,
								uid: inviterId,
								createdAt: serverTimestamp(),
							});
						} else {
							// make sure dont send to client or manager again
							setSentToSign(false);
							setEsignCompleted(xodoSnap.data());
						}
					} else if (esignDocs[3].documentGroupStatus === 'completed') {
						// get the data from the url
						const esignDocsManager = esignDocs;
						if (esignDocsManager && esignDocsManager.length) {
							// get reference to the anvil collection
							const xodoRef = doc(db, 'esigns', emailId);
							// get the data from the anvil collection
							const xodoSnap = await getDoc(xodoRef);
							// make sure dont send to client or manager again
							setSentToSign(false);
							// make sure comment this line below
							setEsignCompleted(xodoSnap.data());
						}
					}
				}
			};

			// notify the rep when the contract looks good
			const looksGoodNoti = async () => {
				const payloadNoti = {
					title: `Contract Looks Good!`,
					message: `${contract && contract[0].contractName} Looks Good to ${
						email
							? `${email.receiver_fname} ${email.receiver_lname}`
							: `<b>${inviteeEmail}</b>`
					} `,
					sender_id: inviterId,
					receiver_id: inviterId,
					seen: false,
					createdAt: serverTimestamp(),
				};
				const notiRef = doc(db, 'notifications', emailId);
				const notiSnap = await getDoc(notiRef);
				if (!notiSnap.exists()) {
					await setDoc(notiRef, payloadNoti);
				} else {
					await updateDoc(notiRef, payloadNoti);
				}
			};

			// verify if the document is signed
			const checkSignedDoc = async () => {
				// verify if the document is signed
				setLoadingViewSign(true);
				// get reference to the anvil collection
				const xodoRef = doc(db, 'esigns', emailId);
				// get the data from the anvil collection
				const xodoSnap = await getDoc(xodoRef);
				if (xodoSnap.exists()) {
					setSentToSign(false);
					setEsignCompleted(xodoSnap.data());
					setEsignExists(true);
					setLoadingViewSign(false);
				} else {
					setSentToSign(true);
					setEsignCompleted(null);
					setLoadingViewSign(false);
				}
			};

			// check if the contract is loaded
			if (contract && contract[0] && contract[0].documentHash && esignData) {
				await postEsign(esignData);
			} else {
				await looksGoodNoti();
			}
			await checkSignedDoc();
		};
		handlePostEsignAndCheckSignedDoc();
	}, [contractLoaded, esignData]);

	useEffect(() => {
		const handleSendClient = async () => {
			await handleProcessSign();
		};
		// send the client to sign the document
		if (
			sentToSign &&
			sentToSign === true &&
			contract &&
			contract[0] &&
			!esignCompleted
		) {
			handleSendClient();
		}
	}, [sentToSign, contract]);

	// get file when sign is completed
	useEffect(() => {
		const getFileAnvil = async () => {
			// make sure dont send to client or manager again
			setSentToSign(false);
			// PROCESS TO GET THE FILE FROM XODO ONLY IF THE DOCUMENT IS SIGNED BY MANAGER
			let file = null;
			if (esignCompleted.data[3].documentGroupStatus === 'completed') {
				const data = {
					documentHash: esignCompleted.data[4].documentHash,
				};
				// get signed document from anvil function in index.js
				const response = await axios.post(
					baseURL + '/downloadDocumentXodoURL',
					data,
					{ responseType: 'arraybuffer' }
				);
				if (response.status === 200) {
					const blob = new Blob([response.data], { type: 'application/pdf' });
					file = blob;
				}
			}
			// Load the file
			if (file) {
				// create a url for the blob
				let pdfurl = window.URL.createObjectURL(file);
				setSignedDocument(pdfurl);
			} else if (!file) {
				console.log('Document is not completed yet');
			}
		};
		if (
			esignCompleted &&
			esignCompleted.data &&
			esignCompleted.data[3].documentGroupStatus === 'completed'
		) {
			getFileAnvil();
		}
	}, [esignCompleted]);

	// download the file
	const handleDownload = async (url, name) => {
		try {
			const blob = await fetch(url)
				.then((r) => r.blob())
				.then((blobFile) => new File([blobFile], name, { type: blobFile.type }));
			const blobUrl = window.URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = `${name}.pdf`;
			document.body.appendChild(link);
			link.click();
		} catch (err) {
			console.log(err);
		}
	};

	const handleRedirectPartial = () => {
		try {
			// get the url to redirect to contract document
			const embeddedURL = contract[0]?.embeddedXodoUrl;
			// replace the sign with status for the url
			const statusURL = embeddedURL.replace(/\/sign$/, '/status');
			// open the url in a new tab
			window.open(statusURL, '_blank');
		} catch (error) {
			console.log(error);
		}
	};

	// check if document is signed
	if (loadingViewSign) return <LoadingView />;

	return (
		<>
			{esignCompleted &&
			esignCompleted.data &&
			esignCompleted.data[0].action === 'signerComplete' ? (
				<Box alignContent={'center'}>
					<Typography variant="body1" mt={3} textAlign={'center'}>
						Signed Contract thank you for visiting.
					</Typography>
					{esignCompleted.data[3].documentGroupStatus === 'completed' &&
					signedDocument ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Stack alignItems="center" spacing={2} direction="column" sx={{ mt: 5 }}>
								<Typography alignContent={'center'}>Click to Download</Typography>

								<IconButton
									onClick={() =>
										handleDownload(signedDocument, contract[0].contractName)
									}
								>
									<DescriptionIcon sx={{ fontSize: '5rem' }} />
								</IconButton>
								<Typography alignContent={'center'}>
									{contract[0].contractName}
								</Typography>
							</Stack>
						</Box>
					) : esignCompleted.data[3].documentGroupStatus === 'partial' &&
					  !signedDocument ? (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Stack alignItems="center" spacing={2} direction="column" sx={{ mt: 5 }}>
								<ClientConfirmationPage />
								<Typography alignContent={'center'}>Click to View</Typography>

								<IconButton onClick={() => handleRedirectPartial()}>
									<DescriptionIcon sx={{ fontSize: '5rem' }} />
								</IconButton>
								<Typography alignContent={'center'}>
									{contract[0].contractName}
								</Typography>
							</Stack>
						</Box>
					) : (
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Stack alignItems="center" spacing={2} direction="row" sx={{ mt: 5 }}>
								<CircularProgress size={18} />
								<Typography alignContent={'center'}>
									Loading document, please wait...
								</Typography>
							</Stack>
						</Box>
					)}
				</Box>
			) : (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					minHeight="100vh"
				>
					<Stack spacing={2} direction="row" alignItems="center">
						<CircularProgress size={18} />
						<Typography alignContent={'center'}>
							Please wait, while we review the status of your document
						</Typography>
					</Stack>
				</Box>
			)}
		</>
	);
};

export default LooksGood;
