import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button, CircularProgress, TextField } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
export default function AddTagProposal({
	setTagProposal,
	openTagProposal,
	tagProposal,
	setOpenTagProposal,
	proposalId,
}) {
	const [loading, setLoading] = useState(false);
	const handleCancel = () => {
		setOpenTagProposal(false);
	};
	const handleSave = async () => {
		if (proposalId) {
			const proposalRef = doc(db, 'savedProposals', proposalId);
			await updateDoc(proposalRef, {
				tagProposal: tagProposal,
			});
		}
		setOpenTagProposal(false);
	};
	return (
		<Dialog
			open={openTagProposal}
			sx={{
				'& .MuiDialog-paper': {
					minWidth: '400px',
				},
			}}
		>
			<DialogTitle>Edit tag proposal</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Tag"
					type="text"
					fullWidth
					value={tagProposal}
					onChange={(e) => setTagProposal(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} variant="contained" color="error">
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					variant="contained"
					color="success"
					startIcon={loading && <CircularProgress color="success" size={18} />}
					disabled={loading}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
